import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { HttpGeneralService } from '../../core/services/http-general/http-general.service';
import { EMPTY, Observable, take, tap } from 'rxjs';
import { directus } from '../../directus';
import { LocalStorage } from '../../core/interfaces/local-storage.interface';
import { CookieService } from 'ngx-cookie-service';

export interface IMe {
  id: string;
  language: string;
  tariff?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MeService {
  private http = inject(HttpGeneralService);
  private localStorage = inject(LocalStorage);
  private cookieService = inject(CookieService);

  me: WritableSignal<IMe | null> = signal(null);

  initializeMe(): Observable<IMe> {
    if (this.localStorage.getItem('google-auth') || this.localStorage.getItem('access_token')) {
      return this.http.get('users/me?fields[]=*,role.name').pipe(
        tap((res) => {
          this.me.set({
            ...res.data,
            tariff: res.data.role.name === 'User' ? 'Free' : res.data.role.name,
          });
          if (this.localStorage.getItem('access_token')) {
            directus.setToken(this.localStorage.getItem('access_token'));
          }
        }),
        take(1),
      );
    } else {
      return EMPTY;
    }
  }

  updateUserLanguage(lang: string): Observable<any> {
    if (this.me()?.id) {
      return this.http.patch(`users/${this.me()?.id}`, { language: lang });
    } else {
      return EMPTY;
    }
  }

  public get isAuthorized(): boolean {
    return !!this.me();
  }

  public get id(): string | undefined {
    return this.me()?.id;
  }
}
