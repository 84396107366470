var t = {
  table: "directus_collections",
  defaults: {
    collection: null,
    hidden: !1,
    singleton: !1,
    icon: null,
    note: null,
    translations: null,
    display_template: null,
    accountability: "all"
  },
  data: [{
    collection: "directus_activity",
    note: "$t:directus_collection.directus_activity",
    accountability: null
  }, {
    collection: "directus_collections",
    icon: "database",
    note: "$t:directus_collection.directus_collections"
  }, {
    collection: "directus_fields",
    icon: "input",
    note: "$t:directus_collection.directus_fields"
  }, {
    collection: "directus_files",
    icon: "folder",
    note: "$t:directus_collection.directus_files",
    display_template: "{{ $thumbnail }} {{ title }}"
  }, {
    collection: "directus_folders",
    note: "$t:directus_collection.directus_folders",
    display_template: "{{ name }}"
  }, {
    collection: "directus_migrations",
    note: "$t:directus_collection.directus_migrations"
  }, {
    collection: "directus_permissions",
    icon: "admin_panel_settings",
    note: "$t:directus_collection.directus_permissions"
  }, {
    collection: "directus_presets",
    icon: "bookmark",
    note: "$t:directus_collection.directus_presets",
    accountability: null
  }, {
    collection: "directus_relations",
    icon: "merge_type",
    note: "$t:directus_collection.directus_relations"
  }, {
    collection: "directus_revisions",
    note: "$t:directus_collection.directus_revisions",
    accountability: null
  }, {
    collection: "directus_roles",
    icon: "supervised_user_circle",
    note: "$t:directus_collection.directus_roles"
  }, {
    collection: "directus_sessions",
    note: "$t:directus_collection.directus_sessions"
  }, {
    collection: "directus_settings",
    singleton: !0,
    note: "$t:directus_collection.directus_settings"
  }, {
    collection: "directus_users",
    archive_field: "status",
    archive_value: "archived",
    unarchive_value: "draft",
    icon: "people_alt",
    note: "$t:directus_collection.directus_users",
    display_template: "{{ first_name }} {{ last_name }}"
  }, {
    collection: "directus_webhooks",
    note: "$t:directus_collection.directus_webhooks"
  }, {
    collection: "directus_dashboards",
    note: "$t:directus_collection.directus_dashboards"
  }, {
    collection: "directus_panels",
    note: "$t:directus_collection.directus_panels"
  }, {
    collection: "directus_notifications",
    note: "$t:directus_collection.directus_notifications"
  }, {
    collection: "directus_shares",
    icon: "share",
    note: "$t:directus_collection.directus_shares"
  }, {
    collection: "directus_flows",
    note: "$t:directus_collection.directus_flows"
  }, {
    collection: "directus_operations",
    note: "$t:directus_collection.directus_operations"
  }, {
    collection: "directus_translations",
    note: "$t:directus_collection.directus_translations"
  }, {
    collection: "directus_versions",
    note: "$t:directus_collection.directus_versions"
  }, {
    collection: "directus_extensions",
    note: "$t:directus_collection.directus_extensions"
  }]
};
var me = t.data.map(i => ({
    ...t.defaults,
    ...i,
    system: !0
  })),
  D = t.data.map(i => i.collection);
function ge(i) {
  return D.includes(i);
}
var l = {
  table: "directus_relations",
  defaults: {
    many_collection: "directus_users",
    many_field: null,
    one_collection: null,
    one_field: null,
    one_allowed_collections: null,
    one_collection_field: null,
    one_deselect_action: "nullify",
    junction_field: null,
    sort_field: null
  },
  data: [{
    many_collection: "directus_collections",
    many_field: "group",
    one_collection: "directus_collections"
  }, {
    many_collection: "directus_users",
    many_field: "role",
    one_collection: "directus_roles",
    one_field: "users"
  }, {
    many_collection: "directus_users",
    many_field: "avatar",
    one_collection: "directus_files"
  }, {
    many_collection: "directus_revisions",
    many_field: "activity",
    one_collection: "directus_activity",
    one_field: "revisions"
  }, {
    many_collection: "directus_revisions",
    many_field: "parent",
    one_collection: "directus_revisions"
  }, {
    many_collection: "directus_revisions",
    many_field: "version",
    one_collection: "directus_versions"
  }, {
    many_collection: "directus_presets",
    many_field: "user",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_presets",
    many_field: "role",
    one_collection: "directus_roles"
  }, {
    many_collection: "directus_folders",
    many_field: "parent",
    one_collection: "directus_folders"
  }, {
    many_collection: "directus_files",
    many_field: "folder",
    one_collection: "directus_folders"
  }, {
    many_collection: "directus_files",
    many_field: "uploaded_by",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_files",
    many_field: "modified_by",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_fields",
    many_field: "collection",
    one_collection: "directus_collections",
    one_field: "fields"
  }, {
    many_collection: "directus_fields",
    many_field: "group",
    one_collection: "directus_fields"
  }, {
    many_collection: "directus_activity",
    many_field: "user",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_settings",
    many_field: "project_logo",
    one_collection: "directus_files"
  }, {
    many_collection: "directus_settings",
    many_field: "public_foreground",
    one_collection: "directus_files"
  }, {
    many_collection: "directus_settings",
    many_field: "public_background",
    one_collection: "directus_files"
  }, {
    many_collection: "directus_settings",
    many_field: "public_favicon",
    one_collection: "directus_files"
  }, {
    many_collection: "directus_settings",
    many_field: "storage_default_folder",
    one_collection: "directus_folders"
  }, {
    many_collection: "directus_panels",
    many_field: "dashboard",
    one_collection: "directus_dashboards",
    one_field: "panels"
  }, {
    many_collection: "directus_panels",
    many_field: "user_created",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_flows",
    many_field: "operation",
    one_collection: "directus_operations"
  }, {
    many_collection: "directus_flows",
    many_field: "user_created",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_operations",
    many_field: "flow",
    one_collection: "directus_flows",
    one_field: "operations",
    one_deselect_action: "delete"
  }, {
    many_collection: "directus_operations",
    many_field: "resolve",
    one_collection: "directus_operations"
  }, {
    many_collection: "directus_operations",
    many_field: "reject",
    one_collection: "directus_operations"
  }, {
    many_collection: "directus_operations",
    many_field: "user_created",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_permissions",
    many_field: "role",
    one_collection: "directus_roles"
  }, {
    many_collection: "directus_sessions",
    many_field: "user",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_sessions",
    many_field: "share",
    one_collection: "directus_shares"
  }, {
    many_collection: "directus_dashboards",
    many_field: "user_created",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_notifications",
    many_field: "recipient",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_notifications",
    many_field: "sender",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_shares",
    many_field: "role",
    one_collection: "directus_roles"
  }, {
    many_collection: "directus_shares",
    many_field: "collection",
    one_collection: "directus_collections"
  }, {
    many_collection: "directus_shares",
    many_field: "user_created",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_versions",
    many_field: "collection",
    one_collection: "directus_collections"
  }, {
    many_collection: "directus_versions",
    many_field: "user_created",
    one_collection: "directus_users"
  }, {
    many_collection: "directus_versions",
    many_field: "user_updated",
    one_collection: "directus_users"
  }]
};
var be = l.data.map(i => ({
  ...l.defaults,
  ...i,
  system: !0
}));
var o = {
  collection: null,
  field: null,
  special: null,
  interface: null,
  options: null,
  display: null,
  display_options: null,
  readonly: !1,
  hidden: !1,
  sort: null,
  width: "full",
  group: null,
  translations: null,
  note: null,
  conditions: null,
  required: !1
};
var a = {
  table: "directus_activity",
  fields: [{
    field: "id",
    width: "half"
  }, {
    field: "item",
    width: "half"
  }, {
    field: "action",
    display: "labels",
    display_options: {
      choices: [{
        text: "$t:field_options.directus_activity.create",
        value: "create",
        foreground: "var(--theme--primary)",
        background: "var(--theme--primary-subdued)"
      }, {
        text: "$t:field_options.directus_activity.update",
        value: "update",
        foreground: "var(--blue)",
        background: "var(--blue-25)"
      }, {
        text: "$t:field_options.directus_activity.delete",
        value: "delete",
        foreground: "var(--theme--danger)",
        background: "var(--danger-25)"
      }, {
        text: "$t:field_options.directus_activity.login",
        value: "login",
        foreground: "var(--purple)",
        background: "var(--purple-25)"
      }]
    },
    width: "half"
  }, {
    field: "collection",
    display: "collection",
    display_options: {
      icon: !0
    },
    width: "half"
  }, {
    field: "timestamp",
    display: "datetime",
    special: ["date-created", "cast-timestamp"],
    options: {
      relative: !0
    },
    width: "half"
  }, {
    field: "user",
    display: "user",
    width: "half"
  }, {
    field: "comment",
    display: "formatted-value",
    display_options: {
      color: "var(--theme--foreground-subdued)"
    },
    width: "half"
  }, {
    field: "user_agent",
    display: "formatted-value",
    display_options: {
      font: "monospace"
    },
    width: "half"
  }, {
    field: "origin",
    display: "formatted-value",
    display_options: {
      font: "monospace"
    },
    width: "half"
  }, {
    field: "ip",
    display: "formatted-value",
    display_options: {
      font: "monospace"
    },
    width: "half"
  }, {
    field: "revisions",
    interface: "list-o2m",
    special: ["o2m"],
    options: {
      fields: ["collection", "item"]
    },
    width: "half"
  }]
};
var s = {
  table: "directus_collections",
  fields: [{
    field: "collection_divider",
    special: ["alias", "no-data"],
    interface: "presentation-divider",
    options: {
      icon: "box",
      title: "$t:field_options.directus_collections.collection_setup"
    },
    width: "full"
  }, {
    field: "collection",
    interface: "input",
    options: {
      font: "monospace"
    },
    readonly: !0,
    width: "half"
  }, {
    field: "note",
    interface: "input",
    options: {
      placeholder: "$t:field_options.directus_collections.note_placeholder"
    },
    width: "half"
  }, {
    field: "icon",
    interface: "select-icon",
    options: null,
    width: "half"
  }, {
    field: "color",
    interface: "select-color",
    options: {
      placeholder: "$t:interfaces.select-color.placeholder"
    },
    width: "half"
  }, {
    field: "display_template",
    interface: "system-display-template",
    options: {
      collectionField: "collection"
    },
    width: "full"
  }, {
    field: "hidden",
    special: ["cast-boolean"],
    interface: "boolean",
    options: {
      label: "$t:field_options.directus_collections.hidden_label"
    },
    width: "half"
  }, {
    field: "singleton",
    special: ["cast-boolean"],
    interface: "boolean",
    options: {
      label: "$t:singleton_label"
    },
    width: "half"
  }, {
    field: "translations",
    special: ["cast-json"],
    interface: "list",
    options: {
      template: "{{ translation }} ({{ language }})",
      fields: [{
        field: "language",
        name: "$t:language",
        type: "string",
        schema: {
          default_value: "en-US"
        },
        meta: {
          interface: "system-language",
          width: "half",
          required: !0
        }
      }, {
        field: "translation",
        name: "$t:field_options.directus_collections.collection_name",
        type: "string",
        meta: {
          interface: "input",
          width: "half",
          required: !0,
          options: {
            placeholder: "$t:field_options.directus_collections.translation_placeholder"
          }
        }
      }, {
        field: "singular",
        name: "$t:field_options.directus_collections.singular_unit",
        type: "string",
        meta: {
          interface: "input",
          width: "half",
          options: {
            placeholder: "$t:field_options.directus_collections.translation_placeholder"
          }
        }
      }, {
        field: "plural",
        name: "$t:field_options.directus_collections.plural_unit",
        type: "string",
        meta: {
          interface: "input",
          width: "half",
          options: {
            placeholder: "$t:field_options.directus_collections.translation_placeholder"
          }
        }
      }]
    },
    width: "full"
  }, {
    field: "preview_divider",
    special: ["alias", "no-data"],
    interface: "presentation-divider",
    options: {
      icon: "preview",
      title: "$t:field_options.directus_collections.preview_divider"
    },
    width: "full"
  }, {
    field: "preview_url",
    interface: "system-display-template",
    options: {
      collectionField: "collection",
      injectVersionField: !0
    },
    width: "full"
  }, {
    field: "content_versioning_divider",
    special: ["alias", "no-data"],
    interface: "presentation-divider",
    options: {
      icon: "published_with_changes",
      title: "$t:field_options.directus_collections.content_versioning_divider"
    },
    width: "full"
  }, {
    field: "versioning",
    interface: "boolean",
    special: ["cast-boolean"],
    options: {
      label: "$t:field_options.directus_collections.enable_versioning"
    },
    width: "half"
  }, {
    field: "archive_divider",
    special: ["alias", "no-data"],
    interface: "presentation-divider",
    options: {
      icon: "archive",
      title: "$t:field_options.directus_collections.archive_divider"
    },
    width: "full"
  }, {
    field: "archive_field",
    interface: "system-field",
    options: {
      collectionField: "collection",
      allowNone: !0,
      placeholder: "$t:field_options.directus_collections.archive_field_placeholder"
    },
    width: "half"
  }, {
    field: "archive_app_filter",
    interface: "boolean",
    special: ["cast-boolean"],
    options: {
      label: "$t:field_options.directus_collections.archive_app_filter"
    },
    width: "half"
  }, {
    field: "archive_value",
    interface: "input",
    options: {
      font: "monospace",
      iconRight: "archive",
      placeholder: "$t:field_options.directus_collections.archive_value"
    },
    width: "half"
  }, {
    field: "unarchive_value",
    interface: "input",
    options: {
      font: "monospace",
      iconRight: "unarchive",
      placeholder: "$t:field_options.directus_collections.unarchive_value"
    },
    width: "half"
  }, {
    field: "sort_divider",
    special: ["alias", "no-data"],
    interface: "presentation-divider",
    options: {
      icon: "sort",
      title: "$t:field_options.directus_collections.sort_divider"
    },
    width: "full"
  }, {
    field: "sort_field",
    interface: "system-field",
    options: {
      collectionField: "collection",
      placeholder: "$t:field_options.directus_collections.sort_field",
      typeAllowList: ["float", "decimal", "integer"],
      allowNone: !0,
      allowPrimaryKey: !1,
      allowForeignKeys: !1
    },
    width: "half"
  }, {
    field: "accountability_divider",
    special: ["alias", "no-data"],
    interface: "presentation-divider",
    options: {
      icon: "admin_panel_settings",
      title: "$t:field_options.directus_collections.accountability_divider"
    },
    width: "full"
  }, {
    field: "accountability",
    interface: "select-dropdown",
    options: {
      choices: [{
        text: "$t:field_options.directus_collections.track_activity_revisions",
        value: "all"
      }, {
        text: "$t:field_options.directus_collections.only_track_activity",
        value: "activity"
      }, {
        text: "$t:field_options.directus_collections.do_not_track_anything",
        value: null
      }]
    },
    width: "half"
  }, {
    field: "duplication_divider",
    special: ["alias", "no-data"],
    interface: "presentation-divider",
    options: {
      icon: "content_copy",
      title: "$t:field_options.directus_collections.duplication_divider"
    }
  }, {
    field: "item_duplication_fields",
    special: ["cast-json"],
    interface: "system-field-tree",
    options: {
      collectionField: "collection"
    }
  }, {
    field: "sort",
    hidden: !0
  }, {
    field: "group",
    hidden: !0
  }, {
    field: "collapse",
    hidden: !0
  }]
};
var n = {
  table: "directus_fields",
  fields: [{
    collection: "directus_fields",
    field: "id",
    width: "half"
  }, {
    collection: "directus_fields",
    field: "collection",
    width: "half"
  }, {
    collection: "directus_fields",
    field: "field",
    width: "half"
  }, {
    collection: "directus_fields",
    field: "special",
    hidden: !0,
    special: ["cast-csv"],
    width: "half"
  }, {
    collection: "directus_fields",
    field: "interface",
    width: "half"
  }, {
    collection: "directus_fields",
    field: "options",
    hidden: !0,
    special: ["cast-json"],
    width: "half"
  }, {
    collection: "directus_fields",
    field: "display",
    width: "half"
  }, {
    collection: "directus_fields",
    field: "display_options",
    hidden: !0,
    special: ["cast-json"],
    width: "half"
  }, {
    collection: "directus_fields",
    field: "readonly",
    hidden: !0,
    special: ["cast-boolean"],
    width: "half"
  }, {
    collection: "directus_fields",
    field: "hidden",
    hidden: !0,
    special: ["cast-boolean"],
    width: "half"
  }, {
    collection: "directus_fields",
    field: "required",
    hidden: !0,
    special: ["cast-boolean"],
    width: "half"
  }, {
    collection: "directus_fields",
    field: "sort",
    width: "half"
  }, {
    collection: "directus_fields",
    field: "width",
    width: "half"
  }, {
    collection: "directus_fields",
    field: "group",
    width: "half"
  }, {
    collection: "directus_fields",
    field: "translations",
    hidden: !0,
    special: ["cast-json"],
    width: "half"
  }, {
    collection: "directus_fields",
    field: "note",
    width: "half"
  }, {
    collection: "directus_fields",
    field: "conditions",
    hidden: !0,
    special: ["cast-json"]
  }, {
    collection: "directus_fields",
    field: "validation",
    hidden: !0,
    special: ["cast-json"]
  }, {
    collection: "directus_fields",
    field: "validation_message",
    hidden: !0
  }]
};
var d = {
  table: "directus_files",
  fields: [{
    field: "id",
    hidden: !0,
    interface: "input",
    special: ["uuid"]
  }, {
    field: "title",
    interface: "input",
    options: {
      iconRight: "title",
      placeholder: "$t:field_options.directus_files.title"
    },
    width: "full"
  }, {
    field: "description",
    interface: "input-multiline",
    width: "full",
    options: {
      placeholder: "$t:field_options.directus_files.description"
    }
  }, {
    field: "tags",
    interface: "tags",
    options: {
      iconRight: "local_offer"
    },
    special: ["cast-json"],
    width: "full",
    display: "labels",
    display_options: {
      choices: null,
      format: !1
    }
  }, {
    field: "location",
    interface: "input",
    options: {
      iconRight: "place",
      placeholder: "$t:field_options.directus_files.location"
    },
    width: "half"
  }, {
    field: "storage",
    interface: "input",
    options: {
      iconRight: "storage"
    },
    width: "half",
    readonly: !0
  }, {
    field: "focal_point_divider",
    interface: "presentation-divider",
    options: {
      icon: "image_search",
      title: "$t:field_options.directus_files.focal_point_divider"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "focal_point_x",
    width: "half"
  }, {
    field: "focal_point_y",
    width: "half"
  }, {
    field: "storage_divider",
    interface: "presentation-divider",
    options: {
      icon: "insert_drive_file",
      title: "$t:field_options.directus_files.storage_divider"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "filename_disk",
    interface: "input",
    options: {
      iconRight: "publish",
      placeholder: "$t:field_options.directus_files.filename_disk"
    },
    readonly: !0,
    width: "half"
  }, {
    field: "filename_download",
    interface: "input",
    options: {
      iconRight: "get_app",
      placeholder: "$t:field_options.directus_files.filename_download"
    },
    width: "half"
  }, {
    field: "metadata",
    hidden: !0,
    special: ["cast-json"]
  }, {
    field: "type",
    display: "mime-type",
    readonly: !0
  }, {
    field: "filesize",
    display: "filesize",
    readonly: !0
  }, {
    field: "modified_by",
    interface: "select-dropdown-m2o",
    special: ["user-updated"],
    width: "half",
    display: "user",
    readonly: !0,
    options: {
      template: "{{avatar.$thumbnail}} {{first_name}} {{last_name}}"
    }
  }, {
    field: "modified_on",
    interface: "datetime",
    special: ["date-updated"],
    width: "half",
    display: "datetime",
    readonly: !0
  }, {
    field: "embed",
    width: "half",
    hidden: !0
  }, {
    field: "uploaded_by",
    display: "user",
    width: "half",
    hidden: !0,
    special: ["user-created"]
  }, {
    field: "uploaded_on",
    display: "datetime",
    width: "half",
    hidden: !0
  }, {
    field: "folder",
    width: "half",
    readonly: !0,
    special: ["m2o"],
    display: "related-values",
    display_options: {
      template: "{{ name }}"
    }
  }, {
    field: "width",
    width: "half",
    readonly: !0
  }, {
    field: "height",
    width: "half",
    readonly: !0
  }, {
    field: "charset",
    width: "half",
    readonly: !0
  }, {
    field: "duration",
    width: "half",
    readonly: !0
  }]
};
var r = {
  table: "directus_folders",
  fields: [{
    field: "id",
    interface: "input",
    special: ["uuid"],
    width: "half"
  }, {
    field: "parent",
    width: "half"
  }, {
    field: "name",
    width: "full"
  }]
};
var c = {
  table: "directus_migrations",
  fields: [{
    collection: "directus_migrations",
    field: "version"
  }, {
    collection: "directus_migrations",
    field: "name"
  }, {
    collection: "directus_migrations",
    field: "timestamp"
  }]
};
var f = {
  table: "directus_permissions",
  fields: [{
    field: "permissions",
    hidden: !0,
    special: ["cast-json"],
    width: "half"
  }, {
    field: "presets",
    hidden: !0,
    special: ["cast-json"],
    width: "half"
  }, {
    field: "role",
    width: "half"
  }, {
    field: "collection",
    width: "half"
  }, {
    field: "id",
    width: "half"
  }, {
    field: "fields",
    width: "half",
    special: ["cast-csv"]
  }, {
    field: "action",
    width: "half"
  }, {
    field: "validation",
    width: "half",
    special: ["cast-json"]
  }]
};
var u = {
  table: "directus_presets",
  fields: [{
    field: "filter",
    hidden: !0,
    special: ["cast-json"]
  }, {
    field: "layout_query",
    hidden: !0,
    special: ["cast-json"]
  }, {
    field: "layout_options",
    hidden: !0,
    special: ["cast-json"]
  }, {
    field: "role",
    width: "half",
    special: ["m2o"],
    display: "related-values",
    display_options: {
      template: "{{ name }}"
    }
  }, {
    field: "user",
    width: "half",
    special: ["m2o"],
    display: "related-values",
    display_options: {
      template: "{{ email }}"
    }
  }, {
    field: "id",
    width: "half"
  }, {
    field: "bookmark",
    width: "half"
  }, {
    field: "icon",
    width: "half"
  }, {
    field: "color",
    width: "half"
  }, {
    field: "search",
    width: "half"
  }, {
    field: "collection",
    width: "half"
  }, {
    field: "layout",
    width: "half"
  }, {
    field: "refresh_interval"
  }]
};
var p = {
  table: "directus_relations",
  fields: [{
    field: "id",
    width: "half"
  }, {
    field: "many_collection",
    width: "half"
  }, {
    field: "many_field",
    width: "half"
  }, {
    field: "one_collection",
    width: "half"
  }, {
    field: "one_field",
    width: "half"
  }, {
    field: "one_collection_field",
    width: "half"
  }, {
    field: "one_allowed_collections",
    special: ["cast-csv"],
    width: "half"
  }, {
    field: "junction_field",
    width: "half"
  }, {
    field: "sort_field",
    width: "half"
  }, {
    field: "one_deselect_action",
    width: "half"
  }]
};
var _ = {
  table: "directus_revisions",
  fields: [{
    field: "id",
    width: "half"
  }, {
    field: "activity",
    width: "half"
  }, {
    field: "collection",
    width: "half"
  }, {
    field: "item",
    width: "half"
  }, {
    field: "data",
    hidden: !0,
    special: ["cast-json"]
  }, {
    field: "delta",
    hidden: !0,
    special: ["cast-json"]
  }, {
    field: "parent",
    width: "half"
  }, {
    field: "version",
    width: "half"
  }]
};
var h = {
  table: "directus_roles",
  fields: [{
    field: "id",
    hidden: !0,
    interface: "input",
    special: ["uuid"]
  }, {
    field: "name",
    interface: "input",
    options: {
      placeholder: "$t:field_options.directus_roles.name"
    },
    width: "half"
  }, {
    field: "icon",
    interface: "select-icon",
    display: "icon",
    width: "half"
  }, {
    field: "description",
    interface: "input",
    options: {
      placeholder: "$t:field_options.directus_roles.description"
    },
    width: "full"
  }, {
    field: "app_access",
    interface: "boolean",
    special: ["cast-boolean"],
    width: "half"
  }, {
    field: "admin_access",
    interface: "boolean",
    special: ["cast-boolean"],
    width: "half"
  }, {
    field: "ip_access",
    interface: "tags",
    options: {
      placeholder: "$t:field_options.directus_roles.ip_access"
    },
    special: ["cast-csv"],
    width: "full"
  }, {
    field: "enforce_tfa",
    interface: "boolean",
    special: ["cast-boolean"],
    width: "half"
  }, {
    field: "users",
    interface: "list-o2m",
    special: ["o2m"],
    options: {
      fields: ["first_name", "last_name"]
    },
    width: "full"
  }]
};
var m = {
  table: "directus_sessions",
  fields: [{
    field: "token",
    width: "half"
  }, {
    field: "user",
    width: "half"
  }, {
    field: "expires",
    width: "half"
  }, {
    field: "ip",
    width: "half"
  }, {
    field: "user_agent",
    width: "half"
  }, {
    field: "origin",
    width: "half"
  }, {
    field: "share"
  }]
};
var g = {
  table: "directus_settings",
  fields: [{
    field: "id",
    hidden: !0
  }, {
    field: "project_name",
    interface: "input",
    options: {
      iconRight: "title",
      placeholder: "$t:field_options.directus_settings.project_name_placeholder"
    },
    width: "half"
  }, {
    field: "project_descriptor",
    interface: "input",
    options: {
      iconRight: "title",
      placeholder: "$t:field_options.directus_settings.project_name_placeholder"
    },
    width: "half"
  }, {
    field: "project_url",
    interface: "input",
    options: {
      iconRight: "link",
      placeholder: "https://example.com"
    },
    width: "half"
  }, {
    field: "default_language",
    interface: "system-language",
    options: {
      iconRight: "language",
      placeholder: "en-US"
    },
    width: "half"
  }, {
    field: "theming_group",
    interface: "group-raw",
    special: ["alias", "no-data", "group"]
  }, {
    field: "branding_divider",
    interface: "presentation-divider",
    options: {
      icon: "palette",
      title: "$t:fields.directus_settings.branding"
    },
    special: ["alias", "no-data"],
    width: "full",
    group: "theming_group"
  }, {
    field: "project_color",
    interface: "select-color",
    note: "$t:field_options.directus_settings.project_color_note",
    width: "half",
    group: "theming_group"
  }, {
    field: "project_logo",
    interface: "file",
    note: "$t:field_options.directus_settings.project_logo_note",
    width: "half",
    group: "theming_group"
  }, {
    field: "public_foreground",
    interface: "file",
    width: "half",
    group: "theming_group"
  }, {
    field: "public_background",
    interface: "file",
    width: "half",
    group: "theming_group"
  }, {
    field: "public_favicon",
    interface: "file",
    note: "$t:field_options.directus_settings.project_favicon_note",
    width: "half",
    group: "theming_group"
  }, {
    field: "public_note",
    interface: "input",
    options: {
      placeholder: "$t:field_options.directus_settings.public_note_placeholder",
      iconRight: "info"
    },
    width: "half",
    group: "theming_group"
  }, {
    field: "theming_divider",
    interface: "presentation-divider",
    options: {
      icon: "palette",
      title: "$t:fields.directus_settings.theming"
    },
    special: ["alias", "no-data"],
    width: "full",
    group: "theming_group"
  }, {
    field: "default_appearance",
    interface: "select-dropdown",
    width: "half",
    options: {
      choices: [{
        value: "auto",
        text: "$t:appearance_auto"
      }, {
        value: "light",
        text: "$t:appearance_light"
      }, {
        value: "dark",
        text: "$t:appearance_dark"
      }]
    },
    group: "theming_group"
  }, {
    field: "default_theme_light",
    width: "full",
    interface: "system-theme",
    options: {
      appearance: "light"
    },
    group: "theming_group"
  }, {
    field: "theme_light_overrides",
    width: "full",
    interface: "system-theme-overrides",
    options: {
      appearance: "light"
    },
    group: "theming_group",
    special: ["cast-json"]
  }, {
    field: "default_theme_dark",
    width: "full",
    interface: "system-theme",
    options: {
      appearance: "dark"
    },
    group: "theming_group"
  }, {
    field: "theme_dark_overrides",
    width: "full",
    interface: "system-theme-overrides",
    options: {
      appearance: "dark"
    },
    group: "theming_group",
    special: ["cast-json"]
  }, {
    field: "custom_css",
    interface: "input-code",
    options: {
      language: "css",
      lineNumber: !0,
      template: `#app, #main-content, body {
  --v-button-background-color: #6644FF !important;
  --v-button-background-color-hover: #5E41EC !important;
}
`
    },
    width: "full",
    group: "theming_group"
  }, {
    field: "modules_divider",
    interface: "presentation-divider",
    options: {
      icon: "menu_open",
      title: "$t:modules"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "module_bar",
    interface: "system-modules",
    special: ["cast-json"]
  }, {
    field: "security_divider",
    interface: "presentation-divider",
    options: {
      icon: "shield",
      title: "$t:security"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "auth_password_policy",
    interface: "select-dropdown",
    options: {
      choices: [{
        value: null,
        text: "$t:field_options.directus_settings.auth_password_policy.none_text"
      }, {
        value: "/^.{8,}$/",
        text: "$t:field_options.directus_settings.auth_password_policy.weak_text"
      }, {
        value: "/(?=^.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{';'?>.<,])(?!.*\\s).*$/",
        text: "$t:field_options.directus_settings.auth_password_policy.strong_text"
      }],
      allowOther: !0
    },
    width: "half"
  }, {
    field: "auth_login_attempts",
    interface: "input",
    options: {
      iconRight: "lock",
      placeholder: "$t:unlimited"
    },
    width: "half"
  }, {
    field: "public_registration_divider",
    interface: "presentation-divider",
    options: {
      icon: "person_add",
      title: "$t:fields.directus_settings.public_registration"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "public_registration",
    interface: "boolean",
    note: "$t:fields.directus_settings.public_registration_note",
    width: "half",
    options: {
      label: "$t:enabled"
    },
    special: ["cast-boolean"]
  }, {
    field: "public_registration_role",
    interface: "select-dropdown-m2o",
    note: "$t:fields.directus_settings.public_registration_role_note",
    options: {
      template: "{{ name }}",
      filter: {
        admin_access: {
          _eq: !1
        }
      }
    },
    special: ["m2o"],
    width: "half",
    display: "related-values",
    display_options: {
      template: "{{ name }}"
    }
  }, {
    field: "public_registration_verify_email",
    interface: "boolean",
    note: "$t:fields.directus_settings.public_registration_verify_email_note",
    width: "half",
    options: {
      label: "$t:enabled"
    },
    special: ["cast-boolean"]
  }, {
    field: "public_registration_email_filter",
    interface: "system-filter",
    note: "$t:fields.directus_settings.public_registration_email_filter_note",
    options: {
      collectionName: "directus_users",
      collectionField: "email",
      fieldName: "email"
    },
    special: ["cast-json"],
    width: "half"
  }, {
    field: "files_divider",
    interface: "presentation-divider",
    options: {
      icon: "folder",
      title: "$t:fields.directus_settings.files_and_thumbnails"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "storage_asset_transform",
    interface: "select-dropdown",
    options: {
      choices: [{
        value: "all",
        text: "$t:fields.directus_settings.transformations_all"
      }, {
        value: "none",
        text: "$t:fields.directus_settings.transformations_none"
      }, {
        value: "presets",
        text: "$t:fields.directus_settings.transformations_presets"
      }]
    },
    width: "half"
  }, {
    field: "storage_default_folder",
    interface: "system-folder",
    width: "half",
    note: "$t:interfaces.system-folder.field_hint"
  }, {
    field: "storage_asset_presets",
    interface: "list",
    options: {
      fields: [{
        field: "key",
        name: "$t:key",
        type: "string",
        schema: {
          is_nullable: !1
        },
        meta: {
          interface: "input",
          options: {
            slug: !0,
            onlyOnCreate: !1
          },
          width: "full",
          required: !0
        }
      }, {
        field: "fit",
        name: "$t:field_options.directus_settings.storage_asset_presets.fit_label",
        type: "string",
        schema: {
          is_nullable: !1
        },
        meta: {
          interface: "select-dropdown",
          options: {
            choices: [{
              value: "contain",
              text: "$t:field_options.directus_settings.storage_asset_presets.fit.contain_text"
            }, {
              value: "cover",
              text: "$t:field_options.directus_settings.storage_asset_presets.fit.cover_text"
            }, {
              value: "inside",
              text: "$t:field_options.directus_settings.storage_asset_presets.fit.fit_text"
            }, {
              value: "outside",
              text: "$t:field_options.directus_settings.storage_asset_presets.fit.outside_text"
            }]
          },
          width: "half"
        }
      }, {
        field: "width",
        name: "$t:width",
        type: "integer",
        schema: {
          is_nullable: !1
        },
        meta: {
          interface: "input",
          width: "half"
        }
      }, {
        field: "height",
        name: "$t:height",
        type: "integer",
        schema: {
          is_nullable: !1
        },
        meta: {
          interface: "input",
          width: "half"
        }
      }, {
        field: "quality",
        type: "integer",
        name: "$t:quality",
        schema: {
          default_value: 80,
          is_nullable: !1
        },
        meta: {
          interface: "slider",
          options: {
            max: 100,
            min: 0,
            step: 1
          },
          width: "half"
        }
      }, {
        field: "withoutEnlargement",
        name: "$t:field_options.directus_settings.storage_asset_presets.upscaling",
        type: "boolean",
        schema: {
          default_value: !1
        },
        meta: {
          interface: "boolean",
          width: "half",
          options: {
            label: "$t:no_upscale"
          }
        }
      }, {
        field: "format",
        name: "$t:format",
        type: "string",
        schema: {
          is_nullable: !1,
          default_value: ""
        },
        meta: {
          interface: "select-dropdown",
          options: {
            allowNone: !0,
            choices: [{
              value: "auto",
              text: "Auto"
            }, {
              value: "jpeg",
              text: "JPEG"
            }, {
              value: "png",
              text: "PNG"
            }, {
              value: "webp",
              text: "WebP"
            }, {
              value: "tiff",
              text: "Tiff"
            }, {
              value: "avif",
              text: "AVIF"
            }]
          },
          width: "half"
        }
      }, {
        field: "transforms",
        name: "$t:field_options.directus_settings.additional_transforms",
        type: "json",
        schema: {
          is_nullable: !1,
          default_value: []
        },
        meta: {
          note: "$t:field_options.directus_settings.transforms_note",
          interface: "json",
          options: {
            template: `[
  ["blur", 45],
  ["grayscale"],
  ["extend", { "right": 500, "background": "rgb(255, 0, 0)" }]
]
`,
            placeholder: `[
  ["blur", 45],
  ["grayscale"],
  ["extend", { "right": 500, "background": "rgb(255, 0, 0)" }]
]
`
          },
          width: "full"
        }
      }],
      template: "{{key}}"
    },
    special: ["cast-json"],
    width: "full"
  }, {
    field: "reporting_divider",
    interface: "presentation-divider",
    options: {
      icon: "feedback",
      title: "$t:fields.directus_settings.reporting"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "report_feature_url",
    interface: "input",
    options: {
      iconRight: "link",
      placeholder: "https://example.com/feature"
    },
    width: "half"
  }, {
    field: "report_bug_url",
    interface: "input",
    options: {
      iconRight: "link",
      placeholder: "https://example.com/bug"
    },
    width: "half"
  }, {
    field: "report_error_url",
    interface: "system-display-template",
    options: {
      placeholder: "https://example.com/error",
      fields: [{
        name: "Error",
        field: "error",
        key: "error",
        path: "error",
        children: [{
          name: "Name",
          field: "name",
          key: "error.name",
          path: "error.name"
        }, {
          name: "Message",
          field: "message",
          key: "error.message",
          path: "error.message"
        }]
      }, {
        name: "Navigator",
        field: "navigator",
        key: "navigator",
        path: "navigator",
        children: [{
          name: "Language",
          field: "language",
          key: "navigator.language",
          path: "navigator.language"
        }, {
          name: "User Agent",
          field: "userAgent",
          key: "navigator.userAgent",
          path: "navigator.userAgent"
        }]
      }, {
        name: "Route",
        field: "route",
        key: "route",
        path: "route",
        children: [{
          name: "Full Path",
          field: "fullPath",
          key: "route.fullPath",
          path: "route.fullPath"
        }, {
          name: "Hash",
          field: "hash",
          key: "route.hash",
          path: "route.hash"
        }, {
          name: "Name",
          field: "name",
          key: "route.name",
          path: "route.name"
        }, {
          name: "Path",
          field: "path",
          key: "route.path",
          path: "route.path"
        }, {
          name: "Query",
          field: "query",
          key: "route.query",
          path: "route.query"
        }]
      }, {
        name: "User",
        field: "user",
        key: "user",
        path: "user",
        children: [{
          name: "ID",
          field: "id",
          key: "user.id",
          path: "user.id"
        }, {
          name: "First Name",
          field: "first_name",
          key: "user.first_name",
          path: "user.first_name"
        }, {
          name: "Last Name",
          field: "last_name",
          key: "user.last_name",
          path: "user.last_name"
        }, {
          name: "Status",
          field: "status",
          key: "user.status",
          path: "user.status"
        }, {
          name: "Title",
          field: "title",
          key: "user.title",
          path: "user.title"
        }, {
          name: "Description",
          field: "description",
          key: "user.description",
          path: "user.description"
        }, {
          name: "Location",
          field: "location",
          key: "user.location",
          path: "user.location"
        }]
      }, {
        name: "Role",
        field: "role",
        key: "role",
        path: "role",
        children: [{
          name: "ID",
          field: "id",
          key: "role.id",
          path: "role.id"
        }, {
          name: "Name",
          field: "name",
          key: "role.name",
          path: "role.name"
        }]
      }]
    },
    width: "full"
  }, {
    field: "map_divider",
    interface: "presentation-divider",
    options: {
      icon: "map",
      title: "$t:fields.directus_settings.mapping"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "mapbox_key",
    interface: "input",
    options: {
      icon: "key",
      title: "$t:field_options.directus_settings.mapbox_key",
      placeholder: "$t:field_options.directus_settings.mapbox_placeholder",
      iconLeft: "vpn_key",
      font: "monospace"
    },
    width: "full"
  }, {
    field: "basemaps",
    interface: "list",
    special: ["cast-json"],
    options: {
      template: "{{name}}",
      fields: [{
        field: "name",
        name: "$t:name",
        schema: {
          is_nullable: !1
        },
        meta: {
          interface: "text-input",
          required: !0,
          options: {
            placeholder: "$t:field_options.directus_settings.basemaps_name_placeholder"
          }
        }
      }, {
        field: "type",
        name: "$t:type",
        meta: {
          interface: "select-dropdown",
          options: {
            choices: [{
              value: "raster",
              text: "$t:field_options.directus_settings.basemaps_raster"
            }, {
              value: "tile",
              text: "$t:field_options.directus_settings.basemaps_tile"
            }, {
              value: "style",
              text: "$t:field_options.directus_settings.basemaps_style"
            }]
          }
        }
      }, {
        field: "url",
        name: "$t:url",
        schema: {
          is_nullable: !1
        },
        meta: {
          interface: "text-input",
          options: {
            placeholder: "http://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          }
        }
      }, {
        field: "tileSize",
        name: "$t:tile_size",
        type: "integer",
        schema: {
          is_nullable: !0
        },
        meta: {
          interface: "input",
          options: {
            placeholder: "512"
          },
          conditions: [{
            name: "typeNeqRaster",
            rule: {
              type: {
                _neq: "raster"
              }
            },
            hidden: !0
          }]
        }
      }, {
        field: "attribution",
        name: "$t:fields.directus_settings.attribution",
        type: "string",
        schema: {
          is_nullable: !0
        },
        meta: {
          interface: "input",
          options: {
            placeholder: "$t:fields.directus_settings.attribution_placeholder"
          }
        }
      }]
    }
  }, {
    field: "image_editor",
    interface: "presentation-divider",
    options: {
      icon: "image",
      title: "$t:fields.directus_settings.image_editor"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "custom_aspect_ratios",
    interface: "list",
    special: ["cast-json"],
    options: {
      template: "{{text}}",
      fields: [{
        field: "text",
        name: "$t:text",
        type: "string",
        meta: {
          interface: "text-input",
          width: "half",
          required: !0,
          options: {
            placeholder: "$t:text"
          }
        }
      }, {
        field: "value",
        name: "$t:value",
        type: "float",
        meta: {
          interface: "input",
          width: "half",
          required: !0,
          options: {
            placeholder: "$t:value"
          }
        }
      }]
    }
  }]
};
var y = {
  table: "directus_users",
  fields: [{
    field: "first_name",
    interface: "input",
    options: {
      iconRight: "account_circle"
    },
    width: "half"
  }, {
    field: "last_name",
    interface: "input",
    options: {
      iconRight: "account_circle"
    },
    width: "half"
  }, {
    field: "email",
    interface: "input",
    options: {
      iconRight: "email"
    },
    width: "half"
  }, {
    field: "password",
    special: ["hash", "conceal"],
    interface: "input-hash",
    options: {
      iconRight: "lock",
      masked: !0
    },
    width: "half"
  }, {
    field: "avatar",
    interface: "file",
    width: "full",
    display: "image"
  }, {
    field: "location",
    interface: "input",
    options: {
      iconRight: "place"
    },
    width: "half"
  }, {
    field: "title",
    interface: "input",
    options: {
      iconRight: "work"
    },
    width: "half"
  }, {
    field: "description",
    interface: "input-multiline",
    width: "full"
  }, {
    field: "tags",
    interface: "tags",
    special: ["cast-json"],
    width: "full",
    options: {
      iconRight: "local_offer"
    },
    display: "labels",
    display_options: {
      choices: null,
      format: !1
    }
  }, {
    field: "preferences_divider",
    interface: "presentation-divider",
    options: {
      icon: "face",
      title: "$t:fields.directus_users.user_preferences"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "language",
    interface: "system-language",
    width: "half",
    options: {
      includeProjectDefault: !0
    }
  }, {
    field: "tfa_secret",
    interface: "system-mfa-setup",
    special: ["conceal"],
    width: "half"
  }, {
    field: "email_notifications",
    interface: "boolean",
    width: "half",
    special: ["cast-boolean"]
  }, {
    field: "theming_divider",
    interface: "presentation-divider",
    options: {
      icon: "palette",
      title: "$t:theme"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "appearance",
    interface: "select-dropdown",
    options: {
      choices: [{
        value: null,
        text: "$t:default_sync_with_project"
      }, {
        value: "auto",
        text: "$t:appearance_auto"
      }, {
        value: "light",
        text: "$t:appearance_light"
      }, {
        value: "dark",
        text: "$t:appearance_dark"
      }]
    },
    width: "half"
  }, {
    field: "theme_light",
    width: "full",
    interface: "system-theme",
    options: {
      appearance: "light",
      includeNull: !0
    }
  }, {
    field: "theme_light_overrides",
    width: "full",
    interface: "system-theme-overrides",
    options: {
      appearance: "light"
    },
    special: ["cast-json"]
  }, {
    field: "theme_dark",
    width: "full",
    interface: "system-theme",
    options: {
      appearance: "dark",
      includeNull: !0
    }
  }, {
    field: "theme_dark_overrides",
    width: "full",
    interface: "system-theme-overrides",
    options: {
      appearance: "dark"
    },
    special: ["cast-json"]
  }, {
    field: "admin_divider",
    interface: "presentation-divider",
    options: {
      icon: "verified_user",
      title: "$t:fields.directus_users.admin_options",
      color: "var(--theme--danger)"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "status",
    interface: "select-dropdown",
    options: {
      choices: [{
        text: "$t:fields.directus_users.status_draft",
        value: "draft"
      }, {
        text: "$t:fields.directus_users.status_invited",
        value: "invited"
      }, {
        text: "$t:fields.directus_users.status_unverified",
        value: "unverified"
      }, {
        text: "$t:fields.directus_users.status_active",
        value: "active"
      }, {
        text: "$t:fields.directus_users.status_suspended",
        value: "suspended"
      }, {
        text: "$t:fields.directus_users.status_archived",
        value: "archived"
      }]
    },
    width: "half"
  }, {
    field: "role",
    interface: "select-dropdown-m2o",
    options: {
      template: "{{ name }}"
    },
    special: ["m2o"],
    width: "half",
    display: "related-values",
    display_options: {
      template: "{{ name }}"
    }
  }, {
    field: "token",
    interface: "system-token",
    special: ["conceal"],
    width: "full"
  }, {
    field: "id",
    special: ["uuid"],
    interface: "input",
    options: {
      iconRight: "vpn_key"
    },
    width: "full"
  }, {
    field: "last_page",
    width: "half"
  }, {
    field: "last_access",
    width: "half",
    display: "datetime",
    readonly: !0,
    display_options: {
      relative: !0
    }
  }, {
    field: "provider",
    width: "half",
    interface: "select-dropdown",
    options: {
      choices: [{
        text: "$t:default_provider",
        value: "default"
      }]
    }
  }, {
    field: "external_identifier",
    width: "half",
    options: {
      iconRight: "account_circle"
    },
    interface: "input"
  }, {
    field: "auth_data",
    hidden: !0
  }]
};
var w = {
  table: "directus_webhooks",
  fields: [{
    field: "id",
    hidden: !0
  }, {
    field: "name",
    interface: "input",
    options: {
      iconRight: "title"
    },
    width: "full"
  }, {
    field: "method",
    interface: "select-dropdown",
    display: "labels",
    display_options: {
      choices: [{
        value: "POST",
        foreground: "var(--theme--primary)",
        background: "var(--theme--primary-subdued)"
      }, {
        value: "GET",
        foreground: "var(--theme--secondary)",
        background: "var(--secondary-25)"
      }],
      format: !1
    },
    options: {
      choices: ["GET", "POST"]
    },
    width: "half"
  }, {
    field: "url",
    interface: "input",
    options: {
      iconRight: "link"
    },
    width: "half"
  }, {
    field: "status",
    interface: "select-dropdown",
    display: "labels",
    display_options: {
      showAsDot: !0,
      choices: [{
        text: "$t:field_options.directus_webhooks.status_options_active",
        value: "active",
        foreground: "var(--theme--primary-background)",
        background: "var(--theme--primary)"
      }, {
        text: "$t:field_options.directus_webhooks.status_options_inactive",
        value: "inactive",
        foreground: "var(--theme--foreground)",
        background: "var(--background-normal-alt)"
      }]
    },
    options: {
      choices: [{
        text: "$t:field_options.directus_webhooks.status_options_active",
        value: "active"
      }, {
        text: "$t:field_options.directus_webhooks.status_options_inactive",
        value: "inactive"
      }]
    },
    width: "half"
  }, {
    field: "data",
    interface: "boolean",
    options: {
      label: "$t:fields.directus_webhooks.data_label"
    },
    special: ["cast-boolean"],
    width: "half",
    display: "boolean"
  }, {
    field: "headers",
    special: ["cast-json"],
    interface: "list",
    options: {
      template: "{{ header }}: {{ value }}",
      addLabel: "$t:field_options.directus_webhooks.headers.add",
      fields: [{
        field: "header",
        name: "$t:field_options.directus_webhooks.headers.header",
        type: "string",
        meta: {
          interface: "input",
          width: "half"
        }
      }, {
        field: "value",
        name: "$t:field_options.directus_webhooks.headers.value",
        type: "string",
        meta: {
          interface: "input",
          width: "half"
        }
      }]
    },
    width: "full"
  }, {
    field: "triggers_divider",
    interface: "presentation-divider",
    options: {
      icon: "api",
      title: "$t:fields.directus_webhooks.triggers"
    },
    special: ["alias", "no-data"],
    width: "full"
  }, {
    field: "actions",
    interface: "select-multiple-checkbox",
    options: {
      choices: [{
        text: "$t:create",
        value: "create"
      }, {
        text: "$t:update",
        value: "update"
      }, {
        text: "$t:delete_label",
        value: "delete"
      }]
    },
    special: ["cast-csv"],
    width: "full",
    display: "labels",
    display_options: {
      choices: [{
        text: "$t:create",
        value: "create",
        foreground: "var(--theme--primary)",
        background: "var(--theme--primary-subdued)"
      }, {
        text: "$t:update",
        value: "update",
        foreground: "var(--blue)",
        background: "var(--blue-25)"
      }, {
        text: "$t:delete_label",
        value: "delete",
        foreground: "var(--theme--danger)",
        background: "var(--danger-25)"
      }, {
        text: "$t:login",
        value: "login",
        foreground: "var(--purple)",
        background: "var(--purple-25)"
      }]
    }
  }, {
    field: "collections",
    interface: "system-collections",
    special: ["cast-csv"],
    width: "full",
    display: "labels",
    display_options: {
      choices: null,
      format: !1
    }
  }, {
    field: "was_active_before_deprecation",
    hidden: !0
  }, {
    field: "migrated_flow",
    hidden: !0
  }]
};
var v = {
  table: "directus_dashboards",
  fields: [{
    field: "id",
    special: ["uuid"]
  }, {
    field: "name"
  }, {
    field: "icon"
  }, {
    field: "panels",
    special: ["o2m"]
  }, {
    field: "date_created",
    special: ["date-created", "cast-timestamp"]
  }, {
    field: "user_created",
    special: ["user-created"]
  }, {
    field: "note"
  }, {
    field: "color"
  }]
};
var b = {
  table: "directus_panels",
  fields: [{
    field: "id",
    special: ["uuid"]
  }, {
    field: "name"
  }, {
    field: "icon"
  }, {
    field: "color"
  }, {
    field: "note"
  }, {
    field: "type"
  }, {
    field: "show_header",
    special: ["cast-boolean"]
  }, {
    field: "position_x"
  }, {
    field: "position_y"
  }, {
    field: "width"
  }, {
    field: "height"
  }, {
    field: "options",
    special: ["cast-json"]
  }, {
    field: "date_created",
    special: ["date-created", "cast-timestamp"]
  }, {
    field: "user_created",
    special: ["user-created"]
  }, {
    field: "dashboard"
  }]
};
var $ = {
  table: "directus_notifications",
  fields: [{
    field: "id"
  }, {
    field: "timestamp",
    special: ["date-created", "cast-timestamp"]
  }, {
    field: "status"
  }, {
    field: "recipient"
  }, {
    field: "sender"
  }, {
    field: "subject"
  }, {
    field: "message"
  }, {
    field: "collection"
  }, {
    field: "item"
  }]
};
var k = {
  table: "directus_shares",
  fields: [{
    field: "id",
    special: ["uuid"],
    readonly: !0,
    hidden: !0
  }, {
    field: "name"
  }, {
    field: "collection",
    width: "half",
    hidden: !0
  }, {
    field: "item",
    width: "half",
    hidden: !0
  }, {
    field: "role",
    interface: "select-dropdown-m2o",
    width: "half",
    options: {
      template: "{{name}}",
      filter: {
        admin_access: {
          _eq: !1
        }
      }
    }
  }, {
    field: "password",
    special: ["hash", "conceal"],
    interface: "input-hash",
    options: {
      iconRight: "lock",
      masked: !0
    },
    width: "half",
    note: "$t:shared_leave_blank_for_passwordless_access"
  }, {
    field: "date_start",
    width: "half",
    note: "$t:shared_leave_blank_for_unlimited"
  }, {
    field: "date_end",
    width: "half",
    note: "$t:shared_leave_blank_for_unlimited"
  }, {
    field: "max_uses",
    width: "half",
    note: "$t:shared_leave_blank_for_unlimited"
  }, {
    field: "times_used",
    width: "half",
    readonly: !0
  }, {
    field: "date_created",
    special: ["date-created", "cast-timestamp"],
    width: "half",
    readonly: !0,
    conditions: [{
      name: "notCreatedYet",
      rule: {
        id: {
          _null: !0
        }
      },
      hidden: !0
    }]
  }, {
    field: "user_created",
    special: ["user-created"],
    interface: "select-dropdown-m2o",
    width: "half",
    display: "user",
    options: {
      template: "{{avatar.$thumbnail}} {{first_name}} {{last_name}}"
    },
    readonly: !0,
    conditions: [{
      name: "notCreatedYet",
      rule: {
        id: {
          _null: !0
        }
      },
      hidden: !0
    }]
  }]
};
var x = {
  table: "directus_flows",
  fields: [{
    field: "id",
    special: ["uuid"]
  }, {
    field: "name"
  }, {
    field: "icon"
  }, {
    field: "color"
  }, {
    field: "description"
  }, {
    field: "status"
  }, {
    field: "trigger"
  }, {
    field: "accountability"
  }, {
    field: "options",
    special: ["cast-json"]
  }, {
    field: "operation"
  }, {
    field: "operations",
    special: ["o2m"]
  }, {
    field: "date_created",
    special: ["date-created"]
  }, {
    field: "user_created",
    special: ["user-created"]
  }]
};
var R = {
  table: "directus_operations",
  fields: [{
    field: "id",
    special: ["uuid"]
  }, {
    field: "name"
  }, {
    field: "key"
  }, {
    field: "type"
  }, {
    field: "position_x"
  }, {
    field: "position_y"
  }, {
    field: "options",
    special: ["cast-json"]
  }, {
    field: "resolve"
  }, {
    field: "reject"
  }, {
    field: "flow"
  }, {
    field: "date_created",
    special: ["date-created"]
  }, {
    field: "user_created",
    special: ["user-created"]
  }]
};
var j = {
  table: "directus_translations",
  fields: [{
    field: "id",
    hidden: !0,
    sort: 1,
    special: ["uuid"]
  }, {
    field: "key",
    width: "half",
    sort: 2,
    required: !0,
    interface: "input",
    options: {
      font: "monospace",
      placeholder: "$t:translation_key_placeholder"
    }
  }, {
    field: "language",
    interface: "system-language",
    width: "half",
    sort: 3,
    required: !0
  }, {
    field: "value",
    interface: "input-multiline",
    sort: 4,
    required: !0,
    options: {
      placeholder: "$t:enter_a_value"
    }
  }]
};
var F = {
  table: "directus_versions",
  fields: [{
    field: "id",
    special: ["uuid"],
    readonly: !0,
    hidden: !0
  }, {
    field: "key"
  }, {
    field: "name"
  }, {
    field: "collection"
  }, {
    field: "item"
  }, {
    field: "hash",
    readonly: !0,
    hidden: !0
  }, {
    field: "date_created",
    special: ["date-created", "cast-timestamp"]
  }, {
    field: "date_updated",
    special: ["date-updated", "cast-timestamp"]
  }, {
    field: "user_created",
    special: ["user-created"]
  }, {
    field: "user_updated",
    special: ["user-updated"]
  }]
};
var q = {
  table: "directus_extensions",
  fields: [{
    collection: "directus_extensions",
    field: "id",
    special: ["uuid"]
  }, {
    collection: "directus_extensions",
    field: "folder"
  }, {
    collection: "directus_extensions",
    field: "source"
  }, {
    collection: "directus_extensions",
    field: "bundle"
  }, {
    collection: "directus_extensions",
    field: "enabled",
    special: ["cast-boolean"]
  }]
};
var ce = [];
e(a);
e(s);
e(n);
e(d);
e(r);
e(c);
e(f);
e(u);
e(p);
e(_);
e(h);
e(m);
e(g);
e(y);
e(w);
e(v);
e(b);
e($);
e(k);
e(x);
e(R);
e(j);
e(F);
e(q);
function e(i) {
  let {
    fields: P,
    table: U
  } = i;
  P.forEach((T, S) => {
    ce.push({
      system: !0,
      ...o,
      ...T,
      collection: U,
      sort: S + 1
    });
  });
}
var E = [{
  collection: "directus_collections",
  action: "read"
}, {
  collection: "directus_fields",
  action: "read"
}, {
  collection: "directus_permissions",
  action: "read",
  permissions: {
    role: {
      _eq: "$CURRENT_ROLE"
    }
  }
}, {
  collection: "directus_relations",
  action: "read"
}];
var N = [{
  collection: "directus_activity",
  action: "read",
  permissions: {
    user: {
      _eq: "$CURRENT_USER"
    }
  }
}, {
  collection: "directus_activity",
  action: "create",
  validation: {
    comment: {
      _nnull: !0
    }
  }
}, {
  collection: "directus_presets",
  action: "read",
  permissions: {
    _or: [{
      user: {
        _eq: "$CURRENT_USER"
      }
    }, {
      _and: [{
        user: {
          _null: !0
        }
      }, {
        role: {
          _eq: "$CURRENT_ROLE"
        }
      }]
    }, {
      _and: [{
        user: {
          _null: !0
        }
      }, {
        role: {
          _null: !0
        }
      }]
    }]
  }
}, {
  collection: "directus_presets",
  action: "create",
  validation: {
    user: {
      _eq: "$CURRENT_USER"
    }
  }
}, {
  collection: "directus_presets",
  action: "update",
  permissions: {
    user: {
      _eq: "$CURRENT_USER"
    }
  }
}, {
  collection: "directus_presets",
  action: "delete",
  permissions: {
    user: {
      _eq: "$CURRENT_USER"
    }
  }
}, {
  collection: "directus_roles",
  action: "read",
  permissions: {
    id: {
      _eq: "$CURRENT_ROLE"
    }
  }
}, {
  collection: "directus_settings",
  action: "read"
}, {
  collection: "directus_translations",
  action: "read"
}, {
  collection: "directus_notifications",
  action: "read",
  permissions: {
    recipient: {
      _eq: "$CURRENT_USER"
    }
  }
}, {
  collection: "directus_notifications",
  action: "update",
  permissions: {
    recipient: {
      _eq: "$CURRENT_USER"
    }
  },
  fields: ["status"]
}, {
  collection: "directus_shares",
  action: "read",
  permissions: {
    user_created: {
      _eq: "$CURRENT_USER"
    }
  }
}, {
  collection: "directus_users",
  action: "read",
  permissions: {
    id: {
      _eq: "$CURRENT_USER"
    }
  },
  fields: ["id", "first_name", "last_name", "last_page", "email", "password", "location", "title", "description", "tags", "preferences_divider", "avatar", "language", "appearance", "theme_light", "theme_dark", "tfa_secret", "status", "role"]
}];
var C = {
    role: null,
    permissions: {},
    validation: null,
    presets: null,
    fields: ["*"],
    system: !0
  },
  pe = E.map(i => ({
    ...C,
    ...i
  })),
  xi = [...pe, ...N].map(i => ({
    ...C,
    ...i
  }));
export { xi as appAccessMinimalPermissions, ge as isSystemCollection, pe as schemaPermissions, D as systemCollectionNames, me as systemCollectionRows, ce as systemFieldRows, be as systemRelationRows };